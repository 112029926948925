<template>
  <div>
    <v-app-bar flat app clipped-left dark color="#F0C316">
      <!-- <v-app-bar-nav-icon @click.stop="drawerToggle" /> -->

      <v-toolbar-title
        class="mr-12 align-center pointer"
        @click="$router.push('/')"
      >
        <span
          class="pl-2 mr-2 font-weight-light"
          v-if="!$vuetify.breakpoint.xsOnly"
          style="color: white; font-size: 25px;"
          >smpl <span class="font-weight-black black--text">sale</span>
        </span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn text @click="$router.push('/')">
        Home
      </v-btn>
      <v-btn text @click="$router.push('/pricing')">
        Pricing
      </v-btn>
      <v-divider vertical> </v-divider>
      <v-btn text @click="$router.push('/signup')">
        Sign Up
      </v-btn>

      <v-btn text @click="$router.push('/login')">
        Login
      </v-btn>
    </v-app-bar>
    <v-content>
      <v-container grid-list-md>
        <v-img src="@/assets/Home/contactus.png"></v-img>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" xs="12" sm="12">
                <v-text-field
                  :error-messages="nameErrors"
                  v-model="customer.name"
                  :background-color="
                    `${customer.name ? false : $style.inputBox.mandatory}`
                  "
                  label="Name"
                  @change="customer.name = properCase(customer.name)"
                  outlined
                  @focus="$event.target.select()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="customer.phoneNumber"
                  outlined
                  :error-messages="phoneNumberErrors"
                  :background-color="
                    `${
                      customer.phoneNumber ? false : $style.inputBox.mandatory
                    }`
                  "
                  @focus="$event.target.select()"
                  label="Phone Number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  outlined
                  :background-color="
                    `${
                      customer.emailAddress ? false : $style.inputBox.mandatory
                    }`
                  "
                  :error-messages="emailAddressErrors"
                  @focus="$event.target.select()"
                  v-model="customer.emailAddress"
                  label="Email Address"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-textarea
                  outlined
                  hide-details
                  auto-grow
                  v-model="customer.message"
                  label="Message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn block large @click="submit" color="success">Send</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-content>
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Thank you for getting in touch!
        </v-card-title>

        <v-card-text class="mt-3">
          <p>
            We appreciate you contacting us. One of our colleagues will get back
            in touch with you soon!
          </p>
          <p>Have a great day!</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GlobalFunction from "@/mixins/GlobalFunction";
import contactUsService from "./service";
const { validationMixin } = require("vuelidate");
const { email, required } = require("vuelidate/lib/validators");

export default {
  data: () => ({
    dialog: false,
    customer: {
      name: null,
      phoneNumber: null,
      emailAddress: null,
      message: null,
    },
  }),
  mixins: [validationMixin, GlobalFunction],
  validations: {
    customer: {
      name: {
        required,
      },
      phoneNumber: {
        required,
      },
      emailAddress: {
        required,
        email,
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      if (!this.$v.customer.name.required) errors.push("Name is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) return errors;
      if (!this.$v.customer.phoneNumber.required)
        errors.push("Phone number is required.");
      return errors;
    },
    emailAddressErrors() {
      const errors = [];
      if (!this.$v.customer.emailAddress.$dirty) return errors;
      if (!this.$v.customer.emailAddress.required)
        errors.push("Email is required.");
      return errors;
    },
  },
  methods: {
    close() {
      this.dialog = false
      this.$router.push('/')
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        return contactUsService
          .create(this.customer)
          .then(() => {
            this.dialog = true;
          })
          .catch((err) => {
            console.log("err", err);
            // this.$swal
            //   .mixin({
            //     toast: true,
            //     position: "top-end",
            //     showConfirmButton: false,
            //     timer: 3000,
            //   })
            //   .fire({
            //     icon: "error",
            //     title: err.data.message,
            //   });
          });
      }
    },
  },
};
</script>
